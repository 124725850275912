import React, { useEffect } from "reactn";

import queryString from "query-string";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const TheTellerPage = (props) => {
  const params = queryString.parse(window.location?.search);
  const api = `${process.env.REACT_APP_PAYMENT_API}`;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params?.email && params?.amount) {
      initiiate();
    }
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (
        event?.data?.name === "closeError" ||
        event?.data?.name === "closeiframe"
      ) {
        window.location.href = `/theteller?status=declined`;
      }
    };
    // Add event listener for message events
    window.addEventListener("message", handleMessage);
    // Clean up by removing the event listener when component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const initiiate = async () => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const requestBody = {
        ref: params?.ref || new Date().getTime().toString().slice(0, 12),
        desc: "Aspire Payment",
        amount: params.amount,
        email: params.email,
      };

      const response = await axios.post(
        `${api}theteller/initiate`,
        requestBody,
        {
          headers: headers,
        }
      );

      const responseData = response.data;
      window.location.href = responseData?.checkout_url;
    } catch (error) {
      toast.error(error?.toString());
      window.location.href = "/theteller?status=declined";
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex justify-content-center  align-center"
      style={{ height: "100vh" }}
    >
      <div>
        {loading && <Spinner animation="border" title="Please wait..." />}
      </div>
    </div>
  );
};

export default TheTellerPage;
